import { curlGet } from '../api/request'
import Vue from '../main'
// 获取订单-稿件未处理数量
export function getOrderNum(array, type, category_id) {
    curlGet('api/users/order/num', {
        category_id: category_id
    }).then(res => {
        // console.log(res.data.data[type],'type');
        if (res.data.code) {
            res.data.data[type].forEach((item, index) => {
                array.forEach((el, i) => {
                    if (array[i].id === res.data.data[type][index].order_status || array[i].id === res.data.data[type][index].limit_time) {
                        // console.log(array[i]);
                        Vue.$set(array[i], 'num', res.data.data[type][index].num)

                    } else {
                        return
                    }
                    // this.$set(array[i],'num',res.data.data[type].num)
                })
            });
        }
    })
}